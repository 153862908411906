import styled from "styled-components"
import media from "styled-media-query"

const Section = styled.section`
  margin: 3em 0;

  ${media.greaterThan("small")`
    margin: 4em 0;
  `}
`

export default Section
