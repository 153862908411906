import styled from "styled-components"

const GradientBackground = styled.div`
  bottom: 0;
  left: 0;
  min-height: 100vh;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transition: background 1.5s ease;
  z-index: -1;
  background: rgb(248, 93, 7);
  background: linear-gradient(
    180deg,
    ${props => (props.gradientValues || []).join(",\n")}
  );
`

export default GradientBackground
