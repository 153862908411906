import styled from "styled-components"
import media from "styled-media-query"

const Container = styled.div`
  margin: 0 auto;
  padding: 1.5rem;

  ${media.greaterThan("medium")`
    max-width: 720px;
  `}

  ${media.greaterThan("large")`
    max-width: 960px;
  `}
`

export default Container
